import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {capitalize} from "../../../utils/capitalize";
import {getZones} from "../../../api/app";
import {useDispatch, useSelector} from "react-redux";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import Icon from "../../../components/svg/truck_blue.svg";
const mapStyle = require("../../../data/backup_map.json");

let anSVGPathString = "M61.2849 48.0244C61.2849 64.3164 48.0769 77.5244 31.7849 77.5244C15.4929 77.5244 2.28491 64.3164 2.28491 48.0244C2.28491 34.9504 22.2469 12.2714 29.6169 3.82141C31.1029 2.11741 33.7479 2.12141 35.2349 3.82441C42.6149 12.2764 61.2849 34.9514 61.2849 48.0244Z"
let aRotationVariable = 98

const GMap = () => {
    const {collectorPositions} = useSelector((state) => state.app);

    const [center, setCenter] = useState({ lat: -15.4510304, lng: 28.4576441 });
    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const zoomLevel = 12;
        const centerLatLng = new window.google.maps.LatLng(center);
        map.setCenter(centerLatLng);
        map.setZoom(zoomLevel);
        setMap(map);
    }, [center]);

    useEffect(() => {
        setCenter({
            lat: collectorPositions?.coords?.latitude || -15.4510304,
            lng: collectorPositions?.coords?.longitude || 28.4576441,
        });
    }, [collectorPositions]);

    // useEffect(() => {
    //     // Animate to the new center when it changes
    //     if (map) {
    //         const bounds = new window.google.maps.LatLngBounds();
    //         bounds.extend(center);
    //         map.fitBounds(bounds, { duration: 2000 }); // You can adjust the duration as needed
    //     }
    // }, [map, center]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBcwPZxow61r9xp4GpD5CJDsJzz1WEXW8Y",
        libraries: ["drawing"]
    });

    return (
        <>
            {!isLoaded ? (
                <h6 style={{marginTop: 40}}>Loading Map...</h6>
            ) : (
                <GoogleMap
                    // options={{
                    //     styles: mapStyle,
                    // }}
                    mapContainerClassName="_map-container"
                    center={center}
                    onLoad={onLoad}
                    zoom={10}
                >
                    {collectorPositions !== null ? <Marker position={{lat: collectorPositions.coords.latitude, lng: collectorPositions.coords.longitude}}
                    /> : null}
                </GoogleMap>
            )}
        </>
    );
};

const LiveTrack = (props) => {

    document.title = "Live Track";
    const {collectorCount} = useSelector((state) => state.app);
    const [loading, setLoading] = useState(true);
    const [areaName, setAreaName] = useState("All Garbage Collector");
    const [collectors, setCollectors] = useState([]);
    const [selectedCollector,  setSelectedCollector] = useState("All Zones");

    useEffect(() => {
        getZones().then(({error, payload}) => {
            if(!error) {
                setCollectors(payload);
                // selectedArea = territories.map((item) => item.suburb._id);
                setLoading(false);
            }
        })
    }, []);

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Live Tracking</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Live Tracking</h4>
                    </div>
                    <div className="d-none d-md-block d-flex">
                        {/*<button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={showDatePicker.bind()}><input type="hidden" id="datepicker"/><i className="wd-10 mg-r-5 fa fa-calendar"></i> Date</button>*/}
                        {loading ? <button disabled
                                           className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase outline-none">
                                <span className="spinner-border spinner-border-sm mg-r-10" role="status"
                                      aria-hidden="true"/> Collecting</button> :
                            <span className="dropdown">
                                <button
                                    className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle"
                                    type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"><i
                                    className="wd-10 mg-r-5 fa fa-globe"></i> {selectedCollector}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    {/*<a href="#" onClick={(e) => {*/}
                                    {/*    e.preventDefault();*/}
                                    {/*    setSelectedCollector(null)*/}
                                    {/*}} className="dropdown-item">All Garbage Collector</a>*/}
                                    {collectors.map((item, index) => {
                                        return (
                                            <a href="#" key={index} onClick={(e) => {
                                                e.preventDefault();
                                                // onAreaSelected(item.suburb)
                                            }} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    })}
                                </div>
                            </span>}
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Live Tracking</h6>
                                <div className="d-flex tx-18">
                                    <ul className="list-inline d-flex mg-md-t-0 mg-b-0">
                                        <li className="list-inline-item d-flex align-items-center">
                                            <span className="d-block wd-10 ht-10 bg-success rounded mg-r-5"></span>
                                            <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">{collectorCount} Online</span>
                                        </li>
                                        <li className="list-inline-item d-flex align-items-center mg-l-5">
                                            <span className="d-block wd-10 ht-10 bg-danger rounded mg-r-5"></span>
                                            <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">0 Offline</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-body">
                                <GMap/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LiveTrack;
