import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';

const DateRangePicker = ({ id, onDateRangeSelect }) => {
    const datePickerRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const handleDateSelect = (selectedDate) => {
            if (!startDate || endDate) {
                setStartDate(selectedDate);
                setEndDate(null);
            } else {
                if (selectedDate < startDate) {
                    setEndDate(startDate);
                    setStartDate(selectedDate);
                } else {
                    setEndDate(selectedDate);
                }
            }

            if (!isClosing) {
                setTimeout(() => $(datePickerRef.current).datepicker('show'), 10);
            }
        };

        $(datePickerRef.current).datepicker({
            numberOfMonths: 1,
            onSelect: function () {
                handleDateSelect($(this).datepicker('getDate'));
            },
            beforeShowDay: function (date) {
                let cssClass = '';
                if (startDate && endDate && date >= startDate && date <= endDate) {
                    cssClass = 'date-range-selected';
                }
                return [true, cssClass];
            },
            showButtonPanel: true,
            closeText: "Done",
            onClose: function () {
                setIsClosing(false);
                if (startDate && endDate) {
                    onDateRangeSelect({ startDate, endDate });
                    clearDateRange();  // Clear the selected date range after selection
                }
            },
            beforeShow: function (input, inst) {
                setIsClosing(false);
                setTimeout(function() {
                    const buttonPane = $(inst.dpDiv).find('.ui-datepicker-buttonpane');
                    buttonPane.find('.ui-datepicker-current').remove();
                    const doneButton = buttonPane.find('.ui-datepicker-close');
                    doneButton.css({
                        width: '100%',
                        textAlign: 'center'
                    });
                }, 1);
            }
        });

        const clearDateRange = () => {
            setStartDate(null);
            setEndDate(null);
            $(datePickerRef.current).datepicker('setDate', null);  // Clear the datepicker UI
        };

        return () => {
            $(datePickerRef.current).datepicker('destroy');
        };
    }, [startDate, endDate, onDateRangeSelect, isClosing]);

    return (
        <input type="hidden" id={id} ref={datePickerRef} readOnly />
    );
};

export default DateRangePicker;