import React, {useEffect} from 'react';
import "../../lib/flot/jquery.flot";
import "../../lib/flot/jquery.flot.resize";
import "jquery.flot.tooltip";
const $ = window.$;

const BarChart = () => {

    useEffect(() => {
        $.plot('#flotChart2', [{
            data: [[0,60],[1,30],[2,50],[3,60],[4,70],[5,70],[6,70],[7,70],[8,80],[9,90],[10,100],[11,130]],
            color: '#69b2f8',
        },{
            data: [[0,80],[1,80],[2,80],[3,80],[4,80],[5,80],[6,80],[7,80],[8,80],[9,80],[10,80],[11,80]],
            color: '#f0f1f5'
        }], {
            series: {
                stack: 0,
                bars: {
                    show: true,
                    lineWidth: 0,
                    barWidth: .3,
                    fill: 1
                }
            },
            grid: {
                borderWidth: 0,
                borderColor: '#edeff6'
            },
            yaxis: {
                show: false,
                max: 80
            },
            xaxis: {
                ticks:[[0,'Jan'],[1.3,'Feb'],[2.3,'Mar'],[3.3,'Apr'],[4.3,'May'],[5.3,'Jun'],[6.3,'Jul'],[7.3,'Aug'],[8.3,'Sep'],[9.3,'Oct'],[10.3,'Nov'],[11.3,'Dec']],
                color: 'rgba(255,255,255,0)'
            },
            tooltip: true
        });
    }, []);

    return (
        <div className="chart-two mg-b-20">
            <div id="flotChart2" className="flot-chart"></div>
        </div>
    );
};

export default BarChart;
