import React, {createContext, useContext, useEffect} from "react";
import io from "socket.io-client";
import { SOCKET_URL } from "../../api";
import {useDispatch} from "react-redux";
import {SET_COLLECTOR_COUNT, SET_COLLECTOR_POSITIONS} from "../../stores/app";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
    const socket = io(SOCKET_URL);

    const dispatch = useDispatch();

    useEffect(() => {
        // Use the socket connection
        socket.on("connect", () => {
            console.log("Connected to the server");
            // Additional logic when connected
        });

        socket.on('userCount', (count) => {
            dispatch(SET_COLLECTOR_COUNT({collectorCount: count}));
        });

        socket.on('position', ({data}) => {
            dispatch(SET_COLLECTOR_POSITIONS({collectorPositions: data}));
        });

        socket.on('disconnect', (data) => {
            console.log(data);
            dispatch(SET_COLLECTOR_POSITIONS({collectorPositions: null}));
            console.log("Disconnected Lost")
        });

        // Clean up on component unmount
        return () => {
            // Disconnect or handle any clean-up when the component unmounts
            socket.disconnect();
        };
    }, [socket]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

const useSocket = () => {
    return useContext(SocketContext);
};

export { SocketProvider, useSocket };
