import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL, ZONE_USER_COUNT_URL} from '../../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-editor';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {getZones, zone} from "api/app";
import {capitalize} from "utils/capitalize";
import axios from "axios";
import EditGMap from "../../../components/map/EditMap";
import MarkedGMap from "../../../components/map/MarkedGMap";
import GMap from "../../../components/map/GMap";
import SubdivisionGMap from "../../../components/map/SubdivisionGMap";
import {areAllPointsInsideMainZone, doPolygonsIntersect} from "../../../utils/geometryUtils";
import EditSubdivisionGMap from "../../../components/map/EditSubdivisionGMap";

let coordinates = null;
let arrayZone = [];
let selectedZone = null;
let mainZone = null;

const Zone = () => {

    document.title = "Zone";

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [geometry, setGeometry] = useState([]);
    const [editPolygon, setEditPolygon] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [contentId, setContentId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [zones, setZones] = useState([]);
    const [checked, setChecked] = useState(false);
    const [zoneLoading, setZoneLoading] = useState(false);
    const [_zoneLoading, _setZoneLoading] = useState(false);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#zone_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Zone Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Zone",
                        fieldname: "name",
                        pageSize: data.start,
                        populate: [
                            {
                                path: 'subdivisions.products',
                                model: 'product',
                                populate: {
                                    path: 'property',
                                    model: 'property',
                                }
                            },
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country',
                                }
                            }
                        ],
                        sort: {name: 1},
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });

                        arrayZone = array;

                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return '<span class="user-count-placeholder" data-id="' + data._id + '">Counting...</span>';
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `<a href="#/collector/${data.collector._id}" class="d-flex tx-color-01">
                                <div class="avatar wd-20 ht-20"><img src="${data.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                                <span class="pd-x-5">${capitalize(data.collector.name)}</span>
                            </a>`;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "inactive" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_zone"><i class="fa fa-edit"></i> Edit</a><a href="#" class="nav-link delete_zone"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 5
                }
            ],
            "rowCallback": function(row, data){
                let id = data.raw._id;
                axios.get(ZONE_USER_COUNT_URL("zone", id))
                    .then(response => {
                        let count = response.data.payload;
                        $(row).find('.user-count-placeholder[data-id="' + id + '"]').text(count);
                    })
                    .catch(error => {
                        $(row).find('.user-count-placeholder[data-id="' + id + '"]').text("Error");
                    });
            }
        });

        table.on('click', '.delete_zone', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete ${extract_td.raw.name}, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                zone("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#zone_table').DataTable().ajax.reload();
                        cogoToast.success("Zone successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.edit_zone', function(e) {
            e.preventDefault();
            setEditPolygon(true);

            let extract_td = table.row($(this).closest('tr')).data();
            setContentId(extract_td.raw._id);

            let collector_select = $("#collector-select2");

            $("#zone-name").val(extract_td.raw.name);
            setEditForm(true);

            initializeCollectorSearch();

            setGeometry(convertCoordinates(extract_td.raw));
            coordinates = convertCoordinates(extract_td.raw);

            let $option = $("<option selected></option>").val(extract_td.raw.collector._id).text(extract_td.raw.collector.name);
            collector_select.append($option).trigger('change');

            $('#zone_form').parsley();

            let {hide} = cogoToast.loading('Please wait... Loading data.', {position: "top-right", hideAfter: 0});

            getZones().then((response) => {
                hide();
                if(!response.error) {
                    setZones(response.payload);
                    const polygons = response.payload.map(zone => ({
                        name: zone.name,
                        collector: zone.collector.name,
                        logo: zone.collector.logo_uri,
                        coordinates: zone.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                        fillColor: getRandomColor()
                    }));

                    const areCoordinatesEqual = (coords1, coords2) => {
                        if (coords1.length !== coords2.length) return false;

                        return coords1.every((coord1, index) =>
                            coord1.lat === coords2[index].lat && coord1.lng === coords2[index].lng
                        );
                    };

                    const filterPolygons = (polygons, geometry) => {
                        return polygons.filter(polygon => !areCoordinatesEqual(polygon.coordinates, geometry));
                    };

                    const filteredPolygons = filterPolygons(polygons, convertCoordinates(extract_td.raw));

                    setZones(filteredPolygons);
                    setZoneLoading(false);

                    $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
                    setOpenMap(true);
                }
            })
        });

        table.on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        let raw  = null;

        function format(d) {
            raw = d.raw;
            return `<tr><td class="tx-13 pd-0">
                        <nav class="d-flex"><span class="text-danger tx-bold">Actions:</span>
                            <a href="#" id="${d.raw._id}-${d.raw.collector._id}" class="nav-link show-subdivision pd-t-0"><i class="fa fa-map"></i> Add Subdivision</a>
                            ${d.raw.subdivisions.length <= 0 ? `<tr><td class="tx-13 pd-0"><span class="tx-13 pd-0">No Subdivision</span></td></tr></tr>` : d.raw.subdivisions.map((item, index) => {
                                return (`<tr><td class="tx-13 pd-0"><nav class="d-flex">${index+1}. ${item.name} (${item.products[0].name} - ${item.products[0].property.name}) : ${raw.collector.country.currency.symbol}${item.products[0].amount}<a href="#" id="${item._id}-${d.raw._id}" class="nav-link pd-t-0 edit-subdivision"><i class="fa fa-pencil"></i> Edit</a><a href="#" id="${item._id}-${d.raw._id}" class="nav-link pd-t-0 pd-l-0 delete-subdivision"><i class="fa fa-trash-alt"></i> Delete</a></nav></td></tr>`)
                            })}`;
        }

        table.on('click', '.show-subdivision', function (e) {
            e.preventDefault();
            setContentId(e.target.id.split('-')[0]);
            setEditPolygon(false);

            let zone = arrayZone.find((item) => item.raw._id === e.target.id.split('-')[0])?.raw;

            mainZone = {
                name: zone.name,
                coordinates: zone.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                fillColor: getRandomColor()
            }

            selectedZone = zone.subdivisions.map(item => ({
                name: item.name,
                coordinates: item.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                fillColor: getRandomColor()
            }));

            setEditForm(false);
            productInitializeSearch(e.target.id.split('-')[1]);

            $("#product-select2").prop("disabled", false).prop("required", true);

            $('#subdivision_form').parsley();
            $("#modalSubdivision").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        table.on('click', '.edit-subdivision', function (e) {
            e.preventDefault();
            setEditPolygon(true);
            setContentId(e.target.id.split('-')[1]);

            let data = raw.subdivisions.find((data) => data._id == e.target.id.split('-')[0]);

            let zone = arrayZone.find((item) => item.raw._id === e.target.id.split('-')[1])?.raw;

            mainZone = {
                name: zone.name,
                coordinates: zone.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                fillColor: getRandomColor()
            }

            selectedZone = zone.subdivisions.map(item => ({
                name: item.name,
                coordinates: item.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                fillColor: getRandomColor()
            }));

            const areCoordinatesEqual = (coords1, coords2) => {
                if (coords1.length !== coords2.length) return false;

                return coords1.every((coord1, index) =>
                    coord1.lat === coords2[index].lat && coord1.lng === coords2[index].lng
                );
            };

            const filterPolygons = (polygons, geometry) => {
                return polygons.filter(polygon => !areCoordinatesEqual(polygon.coordinates, geometry));
            };

            const filteredPolygons = filterPolygons(selectedZone, convertCoordinates(data));

            setZones(filteredPolygons);

            $("#division-name").val(data.name);
            setEditForm(true);
            setEditId(data._id);
            productInitializeSearch();
            setGeometry(convertCoordinates(data));

            let product_select = $("#product-select2");
            let $option = [];

            data.products.map((item) => {
                $option.push($("<option selected></option>").val(item._id).text(item.name))
            })

            product_select.append($option).trigger('change');
            product_select.prop("disabled", true).prop("required", false);

            $('#subdivision_form').parsley();
            $("#modalSubdivision").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        table.on('click', '.delete-subdivision', function (e) {
            e.preventDefault();
            deleteDocument({
                id: e.target.id.split('-')[1] || e.target.id,
                action: "delete-subdivision",
                sub_id: e.target.id.split('-')[0] || e.target.id
            }, 'delete document');
        });
    }, []);

    const deleteDocument = (data, name) => {
        $(".data-message").text(`Are you sure, you want to ${name}?`);
        $('#delete-data').unbind("click").click(function(){

            $("#modalConfirm").modal("hide");
            $(".modal-backdrop").remove();

            const options = {
                position: "top-right",
                hideAfter: 0
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            zone("put", data).then((result) => {
                hide();
                if(!result.error) {
                    $('#zone_table').DataTable().ajax.reload();
                    cogoToast.success(`Document successfully removed.`, {position: "top-right"});
                }
            });
        });

        $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
    }

    const handleZoneSubmit = (e) => {
        e.preventDefault();

        if(coordinates === null) return cogoToast.error("Please, draw a polygon shape on the map.", {position: "top-right", hideAfter: 5});

        setStateReady(true);

        let data = {
            name: $("#zone-name").val(),
            collector: $('#collector-select2').val()[0],
            geometry: coordinates
        };

        if(editForm) {
            data = {
                id: contentId,
                action: "self",
                zone: data
            };

            zone("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalZone", "zone_form");
                    $('#zone_table').DataTable().ajax.reload();
                }
            });

            return false;
        }

        zone("post", data).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success("Zone successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalZone", "zone_form");
                $('#zone_table').DataTable().ajax.reload();
            }
        });
    }

    const initializeCollectorSearch = () => {
        let modalZone = $('#modalZone');
        $('#collector-select2').select2({
            placeholder: 'Select Collector',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalZone,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "collector"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const modalZoneForm = () => {
        _setZoneLoading(true);

        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                const polygons = response.payload.map(zone => ({
                    name: zone.name,
                    collector: zone.collector.name,
                    logo: zone.collector.logo_uri,
                    coordinates: zone.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                    fillColor: getRandomColor()
                }));
                setZones(polygons);
                _setZoneLoading(false);

                setEditForm(false);

                $('#zone_form').parsley();
                initializeCollectorSearch();
                $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
                setOpenMap(true);
            }
        })
    }

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const modalViewZone = () => {
        setZoneLoading(true);

        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                const polygons = response.payload.map(zone => ({
                    name: zone.name,
                    collector: zone.collector.name,
                    logo: zone.collector.logo_uri,
                    coordinates: zone.geometry.coordinates[0].map(coord => ({ lat: coord[0], lng: coord[1] })),
                    fillColor: getRandomColor()
                }));
                setZones(polygons);
                setZoneLoading(false);

                $("#modalViewZone").modal({ backdrop: 'static', focus: false, show: true });
                setOpenMap(true);
            }
        })
    }

    const productInitializeSearch = (id) => {
        let product_select = $('#product-select2');
        let modalZone = $('#modalSubdivision');

        product_select.select2({
            placeholder: 'Select product',
            allowClear: true,
            dropdownParent: modalZone,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "products",
                        filter: {
                            collector: id
                        },
                        populate: [
                            {
                                path: 'property',
                                model: 'property',
                            },
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country',
                                }
                            }
                        ]
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: `${capitalize(item.name)} (${capitalize(item.property.name)}) - ${item.collector.country.currency.symbol}${item.amount}`,
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const handleSubdivisionSubmit = (e) => {
        e.preventDefault();

        const isSubdivisionInsideMainZone = areAllPointsInsideMainZone(coordinates, mainZone.coordinates);

        if (!isSubdivisionInsideMainZone) {
            return cogoToast.error("The subdivision is outside the main zone. Please ensure it is within the boundaries.", { position: "top-right", hideAfter: 5 });
        }

        const isIntersectingWithOtherSubdivisions = selectedZone.some(existingSubdivision =>
            doPolygonsIntersect(existingSubdivision.coordinates, coordinates)
        );

        if (isIntersectingWithOtherSubdivisions) {
            return cogoToast.error("The subdivision intersects with another subdivision. Please adjust its boundaries.", { position: "top-right", hideAfter: 5 });
        }

        setStateReady(true);

        let data = {};

        if(editForm) {
            data = {
                id: contentId,
                action: "update-subdivision",
                meta: {
                    sub_id: editId,
                    name: $("#division-name").val(),
                    geometry: coordinates
                }
            };

            zone("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalSubdivision", "subdivision_form");
                    $('#zone_table').DataTable().ajax.reload();
                }
            });

            return false;
        }

        data = {
            id: contentId,
            action: "add-subdivision",
            division: {
                name: $("#division-name").val(),
                products: $("#product-select2").val(),
                geometry: coordinates
            }
        };

        zone("put", data).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalSubdivision", "subdivision_form");
                $('#zone_table').DataTable().ajax.reload();
            }
        });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        coordinates = null;
        setOpenMap(false);
        setChecked(false);
        setEditPolygon(false);
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const convertCoordinates = (document) => {
        const coordinates = document.geometry.coordinates[0];
        return coordinates.map(coord => ({
            lat: coord[0],
            lng: coord[1]
        }));
    };

    const reloadTable = () => {
        $('#zone_table').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Zone</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Zone</h4>
                    </div>
                    <div className="d-none d-md-block">
                        {zoneLoading ? <button disabled className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={modalViewZone.bind()}><i className="wd-10 mg-r-5 fa fa-map"></i> View Zones</button>}
                        {_zoneLoading ? <button disabled className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={modalZoneForm.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>}
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Zone List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="zone_table" className="table" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Total Users</th>
                                        <th className="wd-20p">Collector</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalZone" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalZone", "zone_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Zone" : "Create Zone"}</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} zone information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="zone_form" className="parsley-style-1" onSubmit={handleZoneSubmit.bind()}>
                                    <div id="zoneNameWrapper" className="form-group parsley-input">
                                        <label>Name<span className="tx-danger">*</span></label>
                                        <input id="zone-name" type="text" className="form-control"
                                               placeholder="Enter zone name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#zoneNameWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="collectorWrapper" className="form-group parsley-input">
                                        <div className="d-flex justify-content-between">
                                            <label>Garbage Collector<span className="tx-danger">*</span></label>
                                        </div>
                                        <select className="form-control" id="collector-select2" data-width="100%"
                                                multiple="multiple"
                                                data-parsley-class-handler="#collectorWrapper"
                                                data-parsley-errors-container="#collectorWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    <div className="custom-control custom-checkbox mb-2">
                                        <input className="custom-control-input" type="checkbox" name="_form[]"
                                               data-parsley-mincheck="1"
                                               data-parsley-class-handler="#cbWrapper2"
                                               data-parsley-errors-container="#cbErrorContainer2"
                                               onClick={(event) => setChecked(event.target.checked)}
                                               data-parsley-multiple="_form" id="b1a"/>
                                        <label className="custom-control-label" htmlFor="b1a">Show Marked Zone</label>
                                    </div>

                                    <div className="mg-b-20">
                                        {openMap ? editPolygon ? <EditGMap polygons={checked ? zones : []} geometry={geometry} onCoordinatesChange={(data) => coordinates = data}/> : <GMap polygons={checked ? zones : []} onCoordinatesChange={(data) => coordinates = data[0]}/> : null}
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-success btn-block">{editForm ? "Update Zone" : "Add Zone"}</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalSubdivision" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalSubdivision", "subdivision_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Subdivision" : "Create Subdivision"}</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} subdivision information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="subdivision_form" className="parsley-style-1"
                                      onSubmit={handleSubdivisionSubmit.bind()}>
                                    <div className="row row-sm mg-b-10">
                                        <div id="divisionNameWrapper"
                                             className="form-group parsley-input col-sm-12">
                                            <label>Name/Area<span className="tx-danger">*</span></label>
                                            <input id="division-name" type="text" className="form-control"
                                                   placeholder="Enter area name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#divisionNameWrapper"
                                                   required
                                            />
                                        </div>

                                        <div id="productWrapper" className="form-group parsley-input col-sm-12">
                                            <div className="d-flex justify-content-between">
                                                <label>Products<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="product-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#productWrapper"
                                                    data-parsley-errors-container="#productWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div className="custom-control custom-checkbox mb-2 ml-4">
                                            <input className="custom-control-input" type="checkbox" name="_form[]"
                                                   data-parsley-mincheck="1"
                                                   data-parsley-class-handler="#cbWrapper3"
                                                   data-parsley-errors-container="#cbErrorContainer3"
                                                   onClick={(event) => setChecked(event.target.checked)}
                                                   data-parsley-multiple="_form" id="b2a"/>
                                            <label className="custom-control-label" htmlFor="b2a">Show Marked Subdivision</label>
                                        </div>
                                    </div>

                                    <div className="mg-b-20">
                                        {openMap ? editPolygon ? <EditSubdivisionGMap mainZone={mainZone} polygons={checked ? zones : []} geometry={geometry} onCoordinatesChange={(data) => coordinates = data}/> : <SubdivisionGMap mainZone={mainZone} polygons={checked ? selectedZone : []} onCoordinatesChange={(data) => coordinates = data[0]}/> : null}
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                        className="spinner-border spinner-border-sm mg-r-10" role="status"
                                        aria-hidden="true"/>Please Wait</button> : <button
                                        className="btn btn-success btn-block">{editForm ? "Update Subdivision" : "Add Subdivision"}</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalViewZone" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalZone", "zone_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">View Marked Zone</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Marked zone information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="mg-b-20">
                                    {openMap ? <MarkedGMap polygons={zones}/> : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content tx-14">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mg-b-0 data-message">Empty</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete
                                </button>
                                <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Zone;
