import React from 'react';
import AreaChart from "../../components/charts/AreaChart";
import BarChart from "../../components/charts/BarChart";
import BarChart1 from "../../components/charts/BarChart1";
import PieChart from "../../components/charts/PieChart";
import $ from "jquery";
import feather from "feather-icons";

const Graph = () => {

    $(function () {
        'use strict';
        feather.replace();
    });

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="row row-xs">
                    <div className="col-lg-8 col-xl-7 mg-t-10">
                        <div className="card ht-md-450">
                            <div className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Recurring Sale Revenue</h6>
                                <ul className="list-inline d-flex mg-t-20 mg-sm-t-10 mg-md-t-0 mg-b-0">
                                    <li className="list-inline-item d-flex align-items-center">
                                        <span className="d-block wd-10 ht-10 bg-df-1 rounded mg-r-5"></span>
                                        <span
                                            className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Growth Actual</span>
                                    </li>
                                    <li className="list-inline-item d-flex align-items-center mg-l-5">
                                        <span className="d-block wd-10 ht-10 bg-df-2 rounded mg-r-5"></span>
                                        <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Actual</span>
                                    </li>
                                    <li className="list-inline-item d-flex align-items-center mg-l-5">
                                        <span className="d-block wd-10 ht-10 bg-df-3 rounded mg-r-5"></span>
                                        <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Plan</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="card-body pos-relative pd-0">
                                <div className="pos-absolute t-20 l-20 wd-xl-100p z-index-10">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5">K3,400.00</h3>
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Total
                                                Annual Revenue</h6>
                                            <p className="mg-b-0 tx-12 tx-color-03">Total annual revenue for all garbage
                                                collector</p>
                                        </div>

                                        <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                                            <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5">K5,000.00</h3>
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Monthly
                                                Revenue</h6>
                                            <p className="mg-b-0 tx-12 tx-color-03">Total Monthly revenue for all
                                                garbage collector</p>
                                        </div>

                                        <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                                            <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5">K7,500.00</h3>
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Average
                                                Monthly Revenue</h6>
                                            <p className="mg-b-0 tx-12 tx-color-03">Average revenue per month for all
                                                garbage collector </p>
                                        </div>
                                    </div>
                                </div>

                                <AreaChart/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-5 mg-t-10">
                        <div className="card ht-md-450">
                            <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                                <h6 className="mg-b-5">Customer Growth</h6>
                                <p className="tx-12 tx-color-03 mg-b-0">Number of customers who have active subscription
                                    with you.</p>
                            </div>
                            <div className="card-body pd-20">
                                <BarChart/>
                                <div className="row mt-5">
                                    <div className="col-sm">
                                        <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">900
                                        </h4>
                                        <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-primary">Total
                                            Customer</p>
                                        <div className="tx-12 tx-color-03">Total accumulated customers for the entire
                                            year.
                                        </div>
                                    </div>

                                    <div className="col-sm mg-t-20 mg-sm-t-0">
                                        <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">K1,340.00
                                        </h4>
                                        <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-pink">Average
                                            Fees</p>
                                        <div className="tx-12 tx-color-03">Average fees paid by customer.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-md-6 col-xl-4 mg-t-10 order-md-1 order-xl-0">*/}
                    {/*    <div className="card ht-lg-100p">*/}
                    {/*        <div className="card-header d-flex align-items-center justify-content-between">*/}
                    {/*            <h6 className="mg-b-0">Sales Revenue</h6>*/}
                    {/*            <div className="tx-13 d-flex align-items-center">*/}
                    {/*                <span className="mg-r-5">Country:</span> <a href="" className="d-flex align-items-center link-03 lh-0">Zambia <ion-icon name="chevron-down-outline" className="mg-l-2"></ion-icon></a>*/}
                    {/*                <span className="mg-r-5 mg-l-5">- State:</span> <a href="" className="d-flex align-items-center link-03 lh-0">Lusaka <ion-icon*/}
                    {/*                name="chevron-down-outline" className="mg-l-2"></ion-icon></a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="card-body pd-0">*/}
                    {/*            <VMap/>*/}
                    {/*            <div className="table-responsive">*/}
                    {/*            <table className="table table-borderless table-dashboard table-dashboard-one">*/}
                    {/*                    <thead>*/}
                    {/*                    <tr>*/}
                    {/*                        <th className="wd-40">States</th>*/}
                    {/*                        <th className="wd-25 text-right">Customers</th>*/}
                    {/*                        <th className="wd-35 text-right">Earnings</th>*/}
                    {/*                    </tr>*/}
                    {/*                    </thead>*/}
                    {/*                    <tbody>*/}
                    {/*                    <tr>*/}
                    {/*                        <td className="tx-medium">Lusaka</td>*/}
                    {/*                        <td className="text-right">12,201</td>*/}
                    {/*                        <td className="text-right">K150,200.80</td>*/}
                    {/*                    </tr>*/}
                    {/*                    <tr>*/}
                    {/*                        <td className="tx-medium">Copperbelt</td>*/}
                    {/*                        <td className="text-right">11,950</td>*/}
                    {/*                        <td className="text-right">K138,910.20</td>*/}
                    {/*                    </tr>*/}
                    {/*                    <tr>*/}
                    {/*                        <td className="tx-medium">Central</td>*/}
                    {/*                        <td className="text-right">11,198</td>*/}
                    {/*                        <td className="text-right">K132,050.00</td>*/}
                    {/*                    </tr>*/}
                    {/*                    <tr>*/}
                    {/*                        <td className="tx-medium">Southern</td>*/}
                    {/*                        <td className="text-right">9,885</td>*/}
                    {/*                        <td className="text-right">K127,762.10</td>*/}
                    {/*                    </tr>*/}
                    {/*                    <tr>*/}
                    {/*                        <td className="tx-medium">North-Western</td>*/}
                    {/*                        <td className="text-right">8,560</td>*/}
                    {/*                        <td className="text-right">K117,087.50</td>*/}
                    {/*                    </tr>*/}
                    {/*                    </tbody>*/}
                    {/*                </table>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-12 col-md-6 col-lg-6 mg-t-10">
                        <div className="card">
                            <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                                <h6 className="lh-5 mg-b-5">Overall Rating</h6>
                                <p className="tx-12 tx-color-03 mg-b-0">Measures the quality or your support team’s efforts.</p>
                            </div>

                            <div className="card-body pd-0">
                                <div className="list-group list-group-flush tx-13">
                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Zoomlion</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Citimop</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Clean Fast</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Lusaka City Council</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Cherishes Clean Care</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">GL Carriers Ltd</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">GP And J Management Services</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">HW Waste Management</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Joyann Trading Ltd</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center justify-content-between">
                                        <div className="tx-rubik tx-color-02">Twincare Commercial Services</div>
                                        <div className="d-flex">
                                            <strong className="tx-12 tx-rubik mg-b-4">5.0</strong>
                                            <div className="tx-14 mg-l-10">
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                                <ion-icon name="star" class="tx-orange"></ion-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 mg-t-10">
                        <div className="card mg-b-10">
                            <div
                                className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                                <div>
                                    <h6 className="mg-b-5">Your Most Recent Earnings</h6>
                                    <p className="tx-13 tx-color-03 mg-b-0">Your sales and referral earnings over the
                                        last 30 days</p>
                                </div>
                                <div className="d-flex mg-t-20 mg-sm-t-0">
                                    <div className="btn-group flex-fill">
                                        <button className="btn btn-white btn-xs active">Lusaka</button>
                                        <button className="btn btn-white btn-xs">Calendar</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body pd-y-30">
                                <div className="d-sm-flex">
                                    <div className="media align-items-center">
                                        <div
                                            className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                                            <i data-feather="bar-chart-2"></i>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">Gross
                                                Earnings</h6>
                                            <h4 className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">K100.00</h4>
                                        </div>
                                    </div>
                                    <div className="media align-items-center mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                                        <div
                                            className="wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                                            <i data-feather="bar-chart-2"></i>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">Total
                                                Credit</h6>
                                            <h4 className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">K200<small>.00</small>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="media align-items-center mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                                        <div
                                            className="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                                            <i data-feather="bar-chart-2"></i>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">Dumpsite
                                                Tonnes</h6>
                                            <h4 className="tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0">40
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-dashboard mg-b-0">
                                    <thead>
                                    <tr>
                                        <th>Garbage Collector</th>
                                        <th className="text-right">Customer</th>
                                        <th className="text-right">Gross Earnings</th>
                                        <th className="text-right">Total Credit</th>
                                        <th className="text-right">Dumpsite Tonnes</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="tx-color-03 tx-normal">Zoomlion</td>
                                        <td className="tx-medium text-right">1000</td>
                                        <td className="text-right tx-teal">+ K200.00</td>
                                        <td className="text-right tx-pink">- K300.00</td>
                                        <td className="tx-medium text-right">100.00 <span
                                            className="mg-l-5 tx-10 tx-normal tx-success d-inline-flex align-items-center"><ion-icon
                                            name="arrow-up-outline" className="mg-b-3"></ion-icon> 9%</span></td>
                                    </tr>
                                    <tr>
                                        <td className="tx-color-03 tx-normal">Citimop</td>
                                        <td className="tx-medium text-right">900</td>
                                        <td className="text-right tx-teal">+ K500.00</td>
                                        <td className="text-right tx-pink">- K300.00</td>
                                        <td className="tx-medium text-right">500.00 <span
                                            className="mg-l-5 tx-10 tx-normal tx-danger d-inline-flex align-items-center"><ion-icon
                                            name="arrow-down-outline" className="mg-b-3"></ion-icon> 8.0%</span></td>
                                    </tr>
                                    <tr>
                                        <td className="tx-color-03 tx-normal">Clean Fast</td>
                                        <td className="tx-medium text-right">200</td>
                                        <td className="text-right tx-teal">+ K100.00</td>
                                        <td className="text-right tx-pink">- K200.00</td>
                                        <td className="tx-medium text-right">600.00 <span
                                            className="mg-l-5 tx-10 tx-normal tx-danger d-inline-flex align-items-center"><ion-icon
                                            name="arrow-down-outline" className="mg-b-3"></ion-icon> 5.0%</span></td>
                                    </tr>
                                    <tr>
                                        <td className="tx-color-03 tx-normal">Lusaka City Council</td>
                                        <td className="tx-medium text-right">1000</td>
                                        <td className="text-right tx-teal">+ K500.00</td>
                                        <td className="text-right tx-pink">- K600.00</td>
                                        <td className="tx-medium text-right">200.00 <span
                                            className="mg-l-5 tx-10 tx-normal tx-danger d-inline-flex align-items-center"><ion-icon
                                            name="arrow-up-outline" className="mg-b-3"></ion-icon> 3.0%</span></td>
                                    </tr>
                                    <tr>
                                        <td className="tx-color-03 tx-normal">Cherishes Clean Care</td>
                                        <td className="tx-medium text-right">50</td>
                                        <td className="text-right tx-teal">+ K50.00</td>
                                        <td className="text-right tx-pink">- K50.00</td>
                                        <td className="tx-medium text-right">10.00 <span
                                            className="mg-l-5 tx-10 tx-normal tx-danger d-inline-flex align-items-center"><ion-icon
                                            name="arrow-up-outline" className="mg-b-3"></ion-icon> 2.0%</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/*<div className="card card-body ht-lg-100">*/}
                        {/*    <div className="media align-items-center">*/}
                        {/*        <span className="tx-color-04"><i data-feather="download" className="wd-60 ht-60"></i></span>*/}
                        {/*        <div className="media-body mg-l-20">*/}
                        {/*            <h6 className="mg-b-10">Download your earnings in CSV format.</h6>*/}
                        {/*            <p className="tx-color-03 mg-b-0">Open it in a spreadsheet and perform your own*/}
                        {/*                calculations, graphing etc.</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="col-lg-8 col-xl-9 mg-t-10">
                        <div className="card">
                            <div
                                className="card-header bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between">
                                <div>
                                    <h6 className="mg-b-5">Garbage Collection Metrics</h6>
                                    <p className="tx-12 tx-color-03 mg-b-0">Audience to which the users belonged while
                                        on the current date range.</p>
                                </div>
                                <div className="btn-group mg-t-20 mg-sm-t-0">
                                    <button className="btn btn-xs btn-white btn-uppercase">Weekly</button>
                                    <button className="btn btn-xs btn-white btn-uppercase">Monthly</button>
                                </div>
                            </div>
                            <div className="card-body pd-lg-25">
                                <div className="row align-items-sm-end">
                                    <BarChart1/>
                                    <div className="col-lg-5 col-xl-4 mg-t-30 mg-lg-t-0">
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-12">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Collections</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex align-items-end justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik lh-2 mg-b-0">0</h5>
                                                    <h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">0</h6>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-teal wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Collected</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik mg-b-0">0</h5>
                                                    <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                        <small>0</small></h5>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-orange wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 mg-t-30">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Uncollected</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik mg-b-0">0</h5>
                                                    <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                        <small>0</small></h5>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-pink wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 mg-t-30">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Collection
                                                        Rate</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik mg-b-0">0%</h5>
                                                    <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                        <small>0%</small></h5>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-primary wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mg-b-0">Dumpsite Frequency Metric</h6>
                            </div>
                            <div className="card-body pd-lg-25">
                                <PieChart/>
                            </div>
                            <div className="card-footer pd-20">
                                <div className="row">
                                    <div className="col-6">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">Yearly</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Monthly</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                    <div className="col-6 mg-t-20">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Weekly</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-teal mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                    <div className="col-6 mg-t-20">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Daily</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-orange mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Graph;
