import { saveAs } from 'file-saver';
const xlsx = require('xlsx');

const jsonExcel = (data) => {
    const { jsonData, fileName, columnWidths } = data;

    // Convert JSON to worksheet
    const worksheet = xlsx.utils.json_to_sheet(jsonData);

    // Set column widths
    if (columnWidths) {
        const columns = [];
        Object.keys(jsonData[0]).forEach((key, index) => {
            columns.push({ wch: columnWidths[index] });
        });
        worksheet['!cols'] = columns;
    }

    // Create workbook
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    // Generate Excel file buffer
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file using FileSaver.js
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, `${fileName}.xlsx`);
}

function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

export default jsonExcel;
