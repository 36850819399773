import React, { useCallback, useState } from "react";
import {DrawingManager, GoogleMap, OverlayView, Polygon, useLoadScript} from "@react-google-maps/api";

const EditSubdivisionGMap = ({ polygons, geometry, mainZone, onCoordinatesChange }) => {
    const [center, setCenter] = useState({ lat: -15.4510304, lng: 28.4576441 });
    const [coordinates, setCoordinates] = useState(geometry);

    const updatePolygonPaths = (polygon) => {
        const paths = polygon.getPath().getArray().map(path => ({
            lat: path.lat(),
            lng: path.lng()
        }));
        setCoordinates(paths);
        if (onCoordinatesChange) {
            onCoordinatesChange(paths); // Pass updated coordinates to the parent
        }
    };

    const onLoad = useCallback(map => {
        const zoomLevel = 10;
        const centerLatLng = new window.google.maps.LatLng(center);
        map.setCenter(centerLatLng);
        map.setZoom(zoomLevel);

        const newPolygon = new window.google.maps.Polygon({
            paths: coordinates,
            strokeColor: '#FD2311',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FD2311',
            fillOpacity: 0.35,
            editable: true,
            draggable: true
        });
        newPolygon.setMap(map);

        const path = newPolygon.getPath();
        path.addListener('set_at', () => updatePolygonPaths(newPolygon));
        path.addListener('insert_at', () => updatePolygonPaths(newPolygon));
        path.addListener('remove_at', () => updatePolygonPaths(newPolygon));

        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(coord => bounds.extend(coord));
        map.fitBounds(bounds);
    }, [center, coordinates]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"]
    });

    const calculateCenter = (coordinates) => {
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(coord => bounds.extend(coord));
        return bounds.getCenter().toJSON();
    };

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            drawingModes: ['polygon']
        },
        polygonOptions: {
            fillColor: '#FD2311',
            strokeColor: '#FD2311',
            fillOpacity: 0.5,
            strokeWeight: 3,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1
        }
    };

    const onPolygonComplete = useCallback(poly => {
        updatePolygonPaths(poly);

        const path = poly.getPath();
        path.addListener('set_at', () => updatePolygonPaths(poly));
        path.addListener('insert_at', () => updatePolygonPaths(poly));
        path.addListener('remove_at', () => updatePolygonPaths(poly));
    }, []);

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    onLoad={onLoad}
                    zoom={10}
                >
                    {mainZone && (
                        <Polygon
                            paths={mainZone.coordinates}
                            options={{
                                fillColor: mainZone.fillColor || "#FD2311",
                                fillOpacity: 0, // No fill
                                strokeColor: "#FD2311",
                                strokeOpacity: 0.6,
                                strokeWeight: 2.5,
                                editable: false,
                                draggable: false
                            }}
                        />
                    )}
                    {/* Render subdivision polygons */}
                    {polygons && polygons.length > 0 && polygons.map((polygon, index) => (
                        <React.Fragment key={index}>
                            <Polygon
                                paths={polygon.coordinates}
                                options={{
                                    fillColor: polygon.fillColor,
                                    fillOpacity: 0.35,
                                    strokeColor: "#FF0000",
                                    strokeOpacity: 0,
                                    strokeWeight: 2.5,
                                    editable: false,
                                    draggable: false
                                }}
                            />
                            <OverlayView
                                position={calculateCenter(polygon.coordinates)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div style={{
                                    display: 'inline-block',
                                    backgroundColor: 'white',
                                    padding: '5px 10px',
                                    borderRadius: '8px',
                                    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
                                    textAlign: 'center',
                                    whiteSpace: 'nowrap'
                                }}>
                                    <h6 style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize: '14px'
                                    }}>
                                        {polygon.name}
                                    </h6>
                                </div>
                            </OverlayView>
                        </React.Fragment>
                    ))}
                    <DrawingManager
                        options={options}
                        onPolygonComplete={onPolygonComplete}
                    />
                </GoogleMap>
            )}
        </>
    );
};

export default EditSubdivisionGMap;
