import axios from "axios";
import {
    SUBURB_URL,
    STATE_URL,
    CITY_URL,
    COUNTRY_URL,
    PRODUCT_URL,
    COMMISSION_URL,
    GET_PRE_SIGNED_URL,
    NOTIFICATION_URL,
    TERRITORY_URL,
    COLLECTOR_URL,
    QRCODE_URL,
    MEDIA_URL,
    USER_URL,
    PICKUP_URL,
    GET_COLLECTOR_URL,
    PAYOUT_URL,
    GET_FINANCE_REPORT_URL,
    GET_TRANSACTION_REPORT_URL,
    SEND_SCHEDULE_MESSAGE_URL,
    GET_ZONE_URL,
    GET_USER_URL,
    UAC_URL,
    WASTE_URL,
    GET_USER_REPORT_URL,
    TRANSACTION_URL,
    GET_WASTE_REPORT_URL,
    GET_TERRITORIES,
    ZONE_URL,
    SCHEDULE_URL,
    GET_COLLECTION_WASTE_REPORT_URL,
    FUMIGATION_URL,
    GET_FUMIGATION_REPORT_URL
} from '../api';
import errorHandler from "../utils/errorHandler";
import {SET_TERRITORIES, SET_COLLECTORS} from "../stores/app";

export const region = (method, data) => {
    return axios({method: method, url: SUBURB_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const state = (method, data) => {
    return axios({method: method, url: STATE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const country = (method, data) => {
    return axios({method: method, url: COUNTRY_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const city = (method, data) => {
    return axios({method: method, url: CITY_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const suburb = (method, data) => {
    return axios({method: method, url: SUBURB_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const product = (method, data) => {
    return axios({method: method, url: PRODUCT_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const commission = (method, data) => {
    return axios({method: method, url: COMMISSION_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const payout = (method, data) => {
    return axios({method: method, url: PAYOUT_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const pickup = (method, data) => {
    return axios({method: method, url: PICKUP_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const fumigation = (method, data) => {
    return axios({method: method, url: FUMIGATION_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const qrcode = (method, data) => {
    return axios({method: method, url: QRCODE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const media = (method, data) => {
    return axios({method: method, url: MEDIA_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const user = (method, data) => {
    return axios({method: method, url: USER_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const transaction = (method, data) => {
    return axios({method: method, url: TRANSACTION_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const admin = (method, data) => {
    return axios({method: method, url: UAC_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const territory = (method, data) => {
    return axios({method: method, url: TERRITORY_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const zone = (method, data) => {
    return axios({method: method, url: ZONE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const schedule = (method, data) => {
    return axios({method: method, url: SCHEDULE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const sendScheduleMessage = (method, data) => {
    return axios({method: method, url: SEND_SCHEDULE_MESSAGE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const waste = (method, data) => {
    return axios({method: method, url: WASTE_URL, data: data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getCollector = (id) => {
    return axios.get(GET_COLLECTOR_URL(id)).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const getUser = (id) => {
    return axios.get(GET_USER_URL(id)).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}
export const collector = (method, data) => {
    return axios({method: method, url: COLLECTOR_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const getWasteReport = (type) => {
    return axios.post(GET_WASTE_REPORT_URL(type)).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getFumigationReport = () => {
    return axios.get(GET_FUMIGATION_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getFinanceReport = (data) => {
    return axios.post(GET_FINANCE_REPORT_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getTransactionReport = (data) => {
    return axios.post(GET_TRANSACTION_REPORT_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getTerritories = () => {
    return function(dispatch) {
        return axios.get(GET_TERRITORIES).then(async(response) => {
            await dispatch(SET_TERRITORIES({territories: response.data.payload}));
            return {
                payload: response.data.payload,
                error: false
            }
        }).catch((error) => {
            errorHandler(error, "top-right");
            return {
                payload: null,
                error: true
            }
        });
    }
};

export const getZones = () => {
    return axios.get(GET_ZONE_URL).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getCollectionWasteReport = (date) => {
    return axios.post(GET_COLLECTION_WASTE_REPORT_URL, {date: new Date(date)}).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const notification = (method, data) => {
    return axios({method: method, url: NOTIFICATION_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}

export const getPreSignedUrl = () => {
    return axios.get(GET_PRE_SIGNED_URL).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

// export const uploadPhoto = (signed_url, binary, config) => {
//     return axios.put(signed_url, binary, config).then((response) => {
//         return {
//             payload: response,
//             error: false
//         }
//     }).catch((error) => {
//         errorHandler(error, "top-right");
//         return {
//             payload: error,
//             error: true
//         }
//     });
// }

export const uploadPhoto = (signed_url, binary, config) => {
    return fetch(signed_url, {
        method: 'PUT',
        body: binary,
        headers: config,
    }).then(response => {
        return {
            payload: response,
            error: false
        }
    }).catch(error => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const getUserReport = () => {
    return axios.get(GET_USER_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
};

export const searchOrderNumber = () => {

}
