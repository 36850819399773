import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_AGGREGATE_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getFinanceReport} from "../../../api/app";
import moment from "moment";
import formatNumber from "../../../utils/formatNumber";
import DateRangePicker from "../../../components/common/DatePicker";

let currentDateRange = null;

const Finance = () => {

    document.title = "Finances";

    const token = localStorage.getItem('jwtToken');
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);
    const [reset, setReset] = useState(new Date().getTime());

    useEffect(() => {

        let data = currentDateRange !== null ? {
            start_date: currentDateRange.start,
            end_date: currentDateRange.end
        } : {}

        getFinanceReport(data).then((response) => {
            if(!response.error) {
                $('#total-revenue').text(formatNumber(response.payload.total_revenue));
                $('#total-payout').text(formatNumber(response.payload.total_payout));
                $('#total-commission').text(formatNumber(response.payload.total_commission));
                $('.current-date').text(response.payload.current_date);
            }
        });
    }, [currentDateRange, reset]);

    useEffect(() => {
        let table = $('#td-transaction').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Finances Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_AGGREGATE_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    contentType: 'application/json',
                    data: JSON.stringify({
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        filter: [
                            {
                                $match: currentDateRange ? {
                                    status: { $in: ["paid", "expired"] },
                                    "paid_date.str": { $gte: currentDateRange.start, $lte: currentDateRange.end }
                                } : {
                                    type: "subscription",
                                    status: { $in: ["paid", "expired"] }
                                }
                            },
                            {
                                $project: {
                                    meta_amount: "$meta.amount",
                                    total_month: "$total_month",
                                    bin: "$bin",
                                    collector: 1
                                }
                            },
                            {
                                $lookup: {
                                    from: "commissions",
                                    let: { collectorId: "$collector" },
                                    pipeline: [
                                        { $match: { $expr: { $eq: ["$collector", "$$collectorId"] } } },
                                        { $sort: { "date.iso": -1 } },
                                        { $limit: 1 }
                                    ],
                                    as: "latestCommission"
                                }
                            },
                            {
                                $addFields: {
                                    latestCommission: { $arrayElemAt: ["$latestCommission", 0] }
                                }
                            },
                            {
                                $addFields: {
                                    commissionRate: { $ifNull: ["$latestCommission.rate", 0] }
                                }
                            },
                            {
                                $group: {
                                    _id: "$collector",
                                    totalAmount: { $sum: { $multiply: ["$meta_amount", "$total_month", "$bin"] } },
                                    commissionRate: { $first: "$commissionRate" },
                                    collectorDataId: { $first: "$collector" }
                                }
                            },
                            {
                                $addFields: {
                                    commissionAmount: { $multiply: ["$totalAmount", { $divide: ["$commissionRate", 100] }] }
                                }
                            },
                            {
                                $sort: { totalAmount: -1 }
                            },
                            {
                                $lookup: {
                                    from: "collectors",
                                    localField: "collectorDataId",
                                    foreignField: "_id",
                                    as: "collector"
                                }
                            },
                            {
                                $unwind: "$collector"
                            },
                            {
                                $lookup: {
                                    from: "payouts",
                                    let: { collectorId: "$collector._id" },
                                    pipeline: [
                                        { $match: { $expr: { $eq: ["$collector", "$$collectorId"] } } },
                                        { $group: { _id: "$collector", totalPayout: { $sum: "$amount" } } }
                                    ],
                                    as: "payoutData"
                                }
                            },
                            {
                                $addFields: {
                                    totalPayout: { $ifNull: [{ $arrayElemAt: ["$payoutData.totalPayout", 0] }, 0] }
                                }
                            },
                            {
                                $project: {
                                    _id: 0,
                                    totalAmount: 1,
                                    commissionAmount: 1,
                                    totalPayout: 1,
                                    "collector.name": 1,
                                    "collector.logo_uri": 1
                                }
                            },
                            {
                                $facet: {
                                    results: [
                                        { $skip: 0 },
                                        { $limit: 10 }
                                    ],
                                    totalCount: [
                                        { $count: "total" }
                                    ]
                                }
                            }
                        ]
                    }),
                    success: function (res) {
                        let array = [];
                        res.data[0].results.map((data) => {
                            array.push({raw: data})
                        });

                        callback({
                            recordsTotal: res.data[0].totalCount[0] ? res.data[0].totalCount[0].total : 0,
                            recordsFiltered: res.data[0].totalCount[0] ? res.data[0].totalCount[0].total : 0,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `<div class="d-flex">
                            <div class="avatar wd-20 ht-20"><img src="${data.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                            <span class="pd-x-5">${capitalize(data.collector.name)}</span>
                        </div>`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<span class="tx-primary tx-bold">K${formatNumber(data.totalAmount)}</span>`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `<span class="tx-success tx-bold">K${formatNumber(data.commissionAmount)}</span>`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `<span class="tx-color-01 tx-bold">K${formatNumber((data.totalAmount - data.commissionAmount) - data.totalPayout)}</span>`;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<span class="tx-danger tx-bold">K${formatNumber(data.totalPayout)}</span>`;
                    },
                    "targets": 4
                }
            ]
        });

        return () => {
            table.destroy();
        }

    }, []);

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        setReset(new Date().getTime());
        $('#td-transaction').DataTable().ajax.reload(null, false);
    }

    const reloadTable = () => {
        setReset(new Date().getTime());
        $('#td-transaction').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Finances</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Finances</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Revenue</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-revenue">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Revenue</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-success tx-semibold mg-b-8">Total Commission</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-commission">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Commissions from Revenue.</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total Disbursed</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-payout">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Revenue after Deducting Commissions.</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Transactions</h6>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect} />
                                            {selectedDate ?
                                                <div>
                                                    <span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span>
                                                    <span className="mg-x-10">to</span>
                                                    <span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span>
                                                </div> : <span>Select Date Range</span>}
                                            <ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-transaction" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Collector</th>
                                        <th className="wd-20p">Revenue</th>
                                        <th className="wd-20p">Commission</th>
                                        <th className="wd-20p">Balance</th>
                                        <th className="wd-20p">Disbursed</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete
                            </button>
                            <button type="button" className="btn btn-danger btn-block mt-0"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body pd-sm-b-40 pd-sm-x-30">

                            <div className="media-body">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                                   aria-label="Close"><span aria-hidden="true">×</span></a>
                                <h4 className="tx-18 tx-sm-20 mg-b-10">Transaction</h4>
                            </div>

                            <ul className="nav nav-line nav-fill" id="myTab5" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="info-tab5" data-toggle="tab" href="#info" role="tab" aria-controls="home" aria-selected="true">Payment</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="customer-tab5" data-toggle="tab" href="#customer" role="tab" aria-controls="profile" aria-selected="false">Customer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="waste-tab5" data-toggle="tab" href="#waste" role="tab" aria-controls="contact" aria-selected="false">Waste</a>
                                </li>
                            </ul>

                            <div className="tab-content mg-t-20" id="myTabContent5">
                                <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab5">
                                    <p><strong>Transaction #:</strong> <span id="tx-transaction-number">---</span></p>
                                    <p><strong>Gateway:</strong> <span id="tx-gateway">---</span></p>
                                    <p><strong>Amount:</strong> ZMW<span id="tx-amount">---</span></p>
                                    <p><strong>Status:</strong> <span id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                </div>
                                <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab5">
                                    <p><strong>Full Name:</strong> <span id="tx-full-name" className="text-capitalize">---</span></p>
                                    <p><strong>Mobile Number:</strong> <span id="tx-mobile-number">---</span></p>
                                    <p><strong>Gender:</strong> <span id="tx-gender" className="text-capitalize">---</span></p>
                                    <p><strong># of Addresses:</strong> <span id="tx-address">---</span></p>
                                    <p><strong>Joined Date:</strong> <span id="tx-joined-date">---</span></p>
                                </div>
                                <div className="tab-pane fade" id="waste" role="tabpanel" aria-labelledby="waste-tab5">
                                    <p><strong>Product:</strong> <span id="tx-product" className="text-capitalize">---</span></p>
                                    <p><strong>Property:</strong> <span id="tx-property" className="text-capitalize">---</span></p>
                                    <p><strong>Collector:</strong> <span id="tx-collector">---</span></p>
                                    <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                    <p><strong>Expiry Date:</strong> <span id="tx-period">---</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Finance;
