import React, { useCallback, useEffect, useRef } from "react";
import { GoogleMap, Polygon, OverlayView, useLoadScript, DrawingManager } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const SubdivisionGMap = ({ mainZone, polygons, onCoordinatesChange }) => {
    const mapRef = useRef(null);
    const drawnPolygonsRef = useRef([]); // To keep track of drawn polygons
    const defaultCenter = { lat: -15.4510304, lng: 28.4576441 }; // Set a default center
    const defaultZoom = 10; // Set a default zoom level

    const onLoad = useCallback(map => {
        mapRef.current = map;

        if ((polygons && polygons.length > 0) || mainZone) {
            const bounds = new window.google.maps.LatLngBounds();

            // Extend bounds for main zone
            if (mainZone && mainZone.coordinates.length > 0) {
                mainZone.coordinates.forEach(coord => bounds.extend(coord));
            }

            // Extend bounds for each subdivision
            polygons.forEach(polygon => {
                polygon.coordinates.forEach(coord => {
                    bounds.extend(coord);
                });
            });

            map.fitBounds(bounds);
        } else {
            map.setCenter(defaultCenter);
            map.setZoom(defaultZoom);
        }
    }, [mainZone, polygons]);

    useEffect(() => {
        if (mapRef.current && ((polygons && polygons.length > 0) || mainZone)) {
            const bounds = new window.google.maps.LatLngBounds();

            // Extend bounds for main zone
            if (mainZone && mainZone.coordinates.length > 0) {
                mainZone.coordinates.forEach(coord => bounds.extend(coord));
            }

            // Extend bounds for each subdivision
            polygons.forEach(polygon => {
                polygon.coordinates.forEach(coord => {
                    bounds.extend(coord);
                });
            });

            mapRef.current.fitBounds(bounds);
        } else if (mapRef.current) {
            mapRef.current.setCenter(defaultCenter);
            mapRef.current.setZoom(defaultZoom);
        }
    }, [mainZone, polygons]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"]
    });

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            drawingModes: ['polygon']
        },
        polygonOptions: {
            fillColor: '#FD2311',
            strokeColor: '#FD2311',
            fillOpacity: 0.5,
            strokeWeight: 3,
            clickable: true,
            editable: true, // Enable editing
            draggable: true,
            zIndex: 1
        }
    };

    const calculateCenter = (coordinates) => {
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(coord => bounds.extend(coord));
        return bounds.getCenter().toJSON();
    };

    const updateDrawnPolygons = () => {
        const updatedDrawnPolygons = drawnPolygonsRef.current.map(poly => {
            const polyArray = poly.getPath().getArray();
            return polyArray.map(path => ({ lat: path.lat(), lng: path.lng() }));
        });
        onCoordinatesChange(updatedDrawnPolygons);
    };

    const onPolygonComplete = useCallback(poly => {
        drawnPolygonsRef.current.push(poly);
        updateDrawnPolygons();

        // Add event listeners to update coordinates when the drawn polygon is edited
        poly.getPath().addListener('set_at', updateDrawnPolygons);
        poly.getPath().addListener('insert_at', updateDrawnPolygons);
        poly.getPath().addListener('remove_at', updateDrawnPolygons);
    }, [onCoordinatesChange]);

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        onLoad={onLoad}
                        options={{ styles: mapStyle }}
                    >
                        {/* Render main zone polygon */}
                        {mainZone && (
                            <Polygon
                                paths={mainZone.coordinates}
                                options={{
                                    fillColor: mainZone.fillColor || "#FD2311",
                                    fillOpacity: 0, // No fill
                                    strokeColor: "#FD2311",
                                    strokeOpacity: 0.6,
                                    strokeWeight: 2.5,
                                    editable: false,
                                    draggable: false
                                }}
                            />
                        )}

                        {/* Render subdivision polygons */}
                        {polygons && polygons.length > 0 && polygons.map((polygon, index) => (
                            <React.Fragment key={index}>
                                <Polygon
                                    paths={polygon.coordinates}
                                    options={{
                                        fillColor: polygon.fillColor,
                                        fillOpacity: 0.35,
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0,
                                        strokeWeight: 2.5,
                                        editable: false,
                                        draggable: false
                                    }}
                                />
                                <OverlayView
                                    position={calculateCenter(polygon.coordinates)}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <div style={{
                                        display: 'inline-block',
                                        backgroundColor: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '8px',
                                        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        <h6 style={{
                                            margin: 0,
                                            padding: 0,
                                            fontSize: '14px'
                                        }}>
                                            {polygon.name}
                                        </h6>
                                    </div>
                                </OverlayView>
                            </React.Fragment>
                        ))}
                        <DrawingManager
                            options={options}
                            onPolygonComplete={onPolygonComplete}
                        />
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default SubdivisionGMap;