import React, { useEffect } from 'react';
import Chart from 'chart.js';

const BarChart1 = () => {
    useEffect(() => {
        // Get the canvas context

        var ctx1 = document.getElementById('chartBar1').getContext('2d');
        new Chart(ctx1, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    data: [40, 100, 140, 100, 300, 450, 600, 900, 1200, 2000, 2500, 3000],
                    backgroundColor: '#66a4fb'
                }, {
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    backgroundColor: '#65e0e0'
                }]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    labels: {
                        display: false
                    }
                },
                scales: {
                    xAxes: [{
                        display: false,
                        barPercentage: 0.5
                    }],
                    yAxes: [{
                        gridLines: {
                            color: '#ebeef3'
                        },
                        ticks: {
                            fontColor: '#8392a5',
                            fontSize: 10,
                            min: 80
                        }
                    }]
                }
            }
        });
    }, []);

    return <div className="col-lg-7 col-xl-8">
        <div className="chart-six"><canvas id="chartBar1"></canvas></div>
    </div>;
};

export default BarChart1;
