import React, {useCallback, useEffect, useRef} from "react";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const ViewGMap = ({ coordinate }) => {
    const mapRef = useRef(null);

    const onLoad = useCallback(map => {
        mapRef.current = map;
        const zoomLevel = 15;
        map.setCenter(coordinate);
        map.setZoom(zoomLevel);
    }, [coordinate]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.panTo(coordinate);
        }
    }, [coordinate]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"]
    });

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        center={coordinate}
                        onLoad={onLoad}
                        zoom={10}
                        options={{ styles: mapStyle }}
                    >
                        <Marker position={coordinate} />
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default ViewGMap;