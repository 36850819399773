// Function to check if a point is inside a polygon using the Ray-Casting algorithm
export const isPointInPolygon = (point, polygon) => {
    const { lat, lng } = point;
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lat, yi = polygon[i].lng;
        const xj = polygon[j].lat, yj = polygon[j].lng;

        const intersect = ((yi > lng) !== (yj > lng)) &&
            (lat < (xj - xi) * (lng - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};

// Function to check if two line segments intersect
export const doLineSegmentsIntersect = (p, q, r, s) => {
    const d1 = (q.lng - p.lng) * (r.lat - p.lat) - (q.lat - p.lat) * (r.lng - p.lng);
    const d2 = (q.lng - p.lng) * (s.lat - p.lat) - (q.lat - p.lat) * (s.lng - p.lng);
    const d3 = (r.lng - s.lng) * (p.lat - r.lat) - (r.lat - s.lat) * (p.lng - r.lng);
    const d4 = (r.lng - s.lng) * (q.lat - r.lat) - (r.lat - s.lat) * (q.lng - r.lng);
    return d1 * d2 < 0 && d3 * d4 < 0;
};

// Function to check if two polygons intersect
export const doPolygonsIntersect = (polygon1, polygon2) => {
    for (let i = 0; i < polygon1.length; i++) {
        const nextI = (i + 1) % polygon1.length;
        const p1 = polygon1[i];
        const q1 = polygon1[nextI];
        for (let j = 0; j < polygon2.length; j++) {
            const nextJ = (j + 1) % polygon2.length;
            const p2 = polygon2[j];
            const q2 = polygon2[nextJ];
            if (doLineSegmentsIntersect(p1, q1, p2, q2)) {
                return true;
            }
        }
    }
    return false;
};

// Function to check if all points of a subdivision are inside the main zone
export const areAllPointsInsideMainZone = (subdivision, mainZone) => {
    return subdivision.every(point => isPointInPolygon(point, mainZone));
};