import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from "../../../api";
import 'datatables.net';
import 'datatables.net-responsive';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import { product, commission } from "api/app";
import {capitalize} from "utils/capitalize";
import moment from "moment/moment";

const Commission = () => {

    document.title = "Commissions";

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#commission').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Commission Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: (data, callback) => {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "commission",
                        fieldname: "name",
                        pageSize: data.start,
                        populate: [
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country'
                                }

                            }
                        ],
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `<div class="d-flex">
                            <div class="avatar wd-20 ht-20"><img src="${data.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                            <span class="pd-x-5">${capitalize(data.collector.name)}</span>
                        </div>`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.rate}%`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.type)
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "depreciate" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only">${data.status === "active" ? `<a href="#" class="nav-link edit_commission"><i class="fa fa-pencil"></i> Edit</a>` : `<nav class="nav-link"><i class="fa fa-ban"></i> Closed</nav>`}<a href="#" class="nav-link delete_commission"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.delete_commission', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.collector.name)} commission?`);
            $('#delete-product').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                commission("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Commission successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.edit_commission', function(e) {
            e.preventDefault();

            initializeCollectorSearch();

            let rate = $("#rate");
            let type = $("#type");
            let collector_select = $("#collector-select2");

            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            setEditId(extract_td.raw._id);

            $('#commission_form').parsley();

            type.val(extract_td.raw.type);
            type.prop("disabled", true).prop("required", false);

            collector_select.prop("disabled", true).prop("required", false);
            rate.val(extract_td.raw.rate);

            let $option = $("<option selected></option>").val(extract_td.raw.collector._id).text(extract_td.raw.collector.name);
            collector_select.append($option).trigger('change');
            $("#modalCommission").modal("show");
        });

        return () => {
            table.destroy();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        let rate = $('#rate').val();

        setStateReady(true);

        if(editForm) {
            let data = {
                id: editId,
                rate
            };

            commission("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalCommission", "commission_form");
                    reloadTable();
                }
            });

            return false;
        }

        let data = {
            rate,
            type: $('#type').val(),
            collector: $('#collector-select2').val()[0]
        };

        commission("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalCommission", "commission_form");
                reloadTable();
            }
        });
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#type').prop("disabled", false).prop("required", true);
        $('#collector-select2').prop("disabled", false).prop("required", true);
        $('#property-select2').prop("disabled", false).prop("required", true);
        initializeCollectorSearch();
        initializePropertySearch();
        $('#commission_form').parsley();
        $("#modalCommission").modal({ backdrop: 'static', focus: false, show: true });
    };

    const initializeCollectorSearch = () => {
        let modalCommission = $('#modalCommission');
        $('#collector-select2').select2({
            placeholder: 'Select garbage collector or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalCommission,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "collector"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const initializePropertySearch = () => {
        let modalCommission = $('#modalCommission');
        $('#property-select2').select2({
            placeholder: 'Select property type or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalCommission,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "property"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        });
    }

    const reloadTable = () => {
        $('#commission').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Commissions</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Commissions</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Commission List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="commission" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Garbage Collector</th>
                                        <th className="wd-20p">Rate</th>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalCommission" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalCommission", "commission_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Commission" : "Create Commission"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} commission information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="commission_form" className="parsley-style-1"
                                          onSubmit={handleSubmit.bind()}>
                                        <div id="collectorWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>Garbage Collector<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="collector-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#collectorWrapper"
                                                    data-parsley-errors-container="#collectorWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="rateWrapper" className="form-group parsley-input">
                                            <label>Rate<span className="tx-danger">*</span></label>
                                            <input id="rate" type="number" className="form-control"
                                                   placeholder="Enter rate"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#rateWrapper" required/>
                                        </div>

                                        <div id="typeWrapper" className="form-group parsley-input">
                                            <label>Type<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="type" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="subscription">Subscription</option>
                                                <option value="pickup">Special Pickup</option>
                                            </select>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                            className="spinner-border spinner-border-sm mg-r-10" role="status"
                                            aria-hidden="true"/>Please Wait</button> : editForm ?
                                            <button className="btn btn-success btn-block">Update Commission</button> :
                                            <button className="btn btn-success btn-block">Create Commission</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog"
                         aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-product" className="btn btn-success btn-block">Yes, Delete</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Commission;
