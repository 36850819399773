import React, {useEffect} from 'react';
import Chart from 'chart.js';

const PieChart = () => {

    useEffect(() => {
        // Chart.register(DoughnutController, ArcElement, Title, Tooltip);

        var datapie = {
            labels: ['Yearly', 'Monthly', 'Weekly', 'Daily'],
            datasets: [{
                data: [20,20,30,25],
                backgroundColor: ['#f77eb9', '#7ebcff','#7ee5e5','#fdbd88']
            }]
        };

        var optionpie = {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
                display: false,
            },
            animation: {
                animateScale: true,
                animateRotate: true
            }
        };

        var ctx2 = document.getElementById('chartDonut');
        new Chart(ctx2, {
            type: 'doughnut',
            data: datapie,
            options: optionpie
        });
    }, []);

    return (
        <div className="chart-seven">
            <canvas id="chartDonut"></canvas>
        </div>
    );
};

export default PieChart;
